import React, { memo } from 'react'
import { BWPanelContent, cssCustomGap } from 'src/libs/qb-brand-web-components'
import styled from '@emotion/styled'

import OverviewEnterCodeWrapper from 'wrappers/OverviewEnterCodeWrapper'

const OverviewEnterCode = ({
  howToEarnLink,
  howDoesItWorkButton,
  t,
  onEnterRewardCodeClick
}) => {
  return (
    <CustomGridContent>
      {howToEarnLink}
      <OverviewEnterCodeWrapper t={t} onClick={onEnterRewardCodeClick} />
      {howDoesItWorkButton}
    </CustomGridContent>
  )
}

export default memo(OverviewEnterCode)

const CustomGridContent = styled(BWPanelContent)`
  ${cssCustomGap(15)};
`

import React, { memo } from 'react'

import { BWLink } from '../../../libs/qb-brand-web-components'
import customConfig from 'customConfig'

const OverviewEnterCodeWrapper = ({ t, ...restProps }) => {
  return (
    <BWLink
      label={t(customConfig.overviewEnterRewardCodeLabel)}
      {...restProps}
    />
  )
}

export default memo(OverviewEnterCodeWrapper)
